import React from 'react';
import { Container, Col } from 'react-materialize';
import { COLOR, SIZE, LABEL } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Banner from '../organism/banner.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import TextButton from '../atoms/textbutton.jsx';

import PathIcEduInitial from '../../icons/ic_edu_initial.svg';
import PathIcEduNotebook from '../../icons/ic_edu_notebook.svg';
import PathIcAtom from '../../icons/ic_atom.svg';
import PathIcEduGraduation from '../../icons/ic_edu_graduation.svg';
import PathIcEduBooks from '../../icons/ic_edu_books.svg';
import PathIcMedal from '../../icons/ic_medal.svg';
import PathIcFile from '../../icons/ic_file.svg';
import PathIcBadge from '../../icons/ic_badge.svg';
import PathIcDocument from '../../icons/ic_document.svg';


const BodyOurProposal = () => {
    return (
    <div>
        <Banner title={LABEL.ourProposal}/>

        <Container>
            <Row
                margin='0 0'>
                <Path 
                    endpoint={LABEL.ourProposal}
                    margin='20px 0' />
            </Row>
            <Row>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcEduInitial}
                        ic_color={COLOR.gold}
                        subtitle='Inicial'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Aprestamiento a la Matemática, al lenguaje y personal social." },
                            { item: "Desarrollo de capacidades afectivas y capacidades motrices." },
                            { item: "Desarrollo de habilidades sociales." }
                        ]}/>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcEduNotebook}
                        ic_color={COLOR.gold}
                        subtitle='Primaria'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Valores y creencias claros y compartidos." },
                            { item: "Desarrollo de habilidades cognitivas." },
                            { item: "Evaluaciones frecuentes del progreso educativo." }
                        ]}/>
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcAtom}
                        ic_color={COLOR.gold}
                        subtitle='Secundaria'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Valores y creencias claras y compartidas." },
                            { item: "Exigencia en la consolidación de conocimientos de Matemática, Ciencias y Letras." },
                            { item: "Desarrollo personal." },
                            { item: "Altas expectativas en torno a los resultados académicos de los alumnos." },
                            { item: "Evaluaciones frecuentes del progreso educativo." }
                        ]}/>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcEduGraduation}
                        ic_color={COLOR.gold}
                        subtitle='Sistema 5º año'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Los estudiantes son agrupados en aulas, de: Ingeniería, Ciencias y Letras." },
                            { item: "Orientación vocacional usando técnicas de coaching y PNL." },
                            { item: "Simulacros de exámenes de admisión a la universidad." },
                            { item: "Altas expectativas en torno a los resultados académicos de los alumnos." }
                        ]}/>
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcEduBooks}
                        ic_color={COLOR.gold}
                        subtitle='Docentes'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Egresados de Universidad de prestigio, con amplia experiencia en educación." },
                            { item: "Expertos en preparación preuniversitaria, con dominio de técnicas de aprendizaje (rápido y fácil)." },
                            { item: "Profesores comprometidos con la educación." }
                        ]}/>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcMedal}
                        ic_color={COLOR.gold}
                        subtitle='Desarrollo Personal'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Autoconocimiento." },
                            { item: "Desarrollo de habilidades sociales." },
                            { item: "El lenguaje y la manera de ser." },
                            { item: "El poder de las conversaciones." },
                            { item: "Inteligencia emocional." },
                            { item: "Liderazgo." }
                        ]}/>
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcFile}
                        ic_color={COLOR.gold}
                        subtitle='Identidad Nacional'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "El tutor acompaña al estudiante en su formación académica y humana." },
                            { item: "Está en comunicación permanente con la familia." }
                        ]}/>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <CardContent
                        ic_path={PathIcBadge}
                        ic_color={COLOR.gold}
                        subtitle='Desarrollo Personal'
                        subtitle_size={SIZE.title}
                        title_separation='15px'
                        alignment='center'
                        bullet_items={[
                            { item: "Símbolos de la patria." },
                            { item: "Creencias y costumbres del Perú." },
                            { item: "Fiestas y celebraciones." },
                            { item: "Sitios arqueológicos y turísticos." },
                            { item: "Folklore." }
                        ]}/>
                </Col>
            </Row>
            <Row>
                <Col s={12} m={12} l={6} xl={6}>
                    <TextButton
                        ic_path={PathIcDocument}
                        ic_color={COLOR.gold}
                        href='/conditionRules'
                        target='_blank'>
                        { LABEL.conditionRules }
                    </TextButton>
                </Col>
            </Row>
        </Container>
    </div>
    );
}

export default BodyOurProposal;