import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/organism/header.jsx';
import Footer from './components/organism/footer.jsx';
import BodyIndex from './components/templates/bodyindex.jsx';
import BodyUs from './components/templates/bodyus.jsx';
import BodyOurProposal from './components/templates/bodyourproposal.jsx';
import BodyContact from './components/templates/bodycontact.jsx';
import BodyService from './components/templates/bodyservice.jsx';
import BodyConditionRules from './components/templates/bodyconditionrules.jsx';
import PDFViewer from './components/templates/bodypdfviewer.jsx';
import { Root } from './utils/global.js';
import { LABEL } from './utils/constants.js';
import { 
    FontSailecRegular, 
    FontSailecMedium, 
    FontSailecBold, 
    FontSailecLight, 
    FontHarmonyRegular, 
    FontHarmonyLite } from './utils/fonts.js';

import PathPDFConditions from './pdf/conditions_2024.pdf';
import PathPDFRules from './pdf/rules_2024.pdf';

const App = () => {
    return(
        <div>
            <Root/>
            <FontSailecRegular/>
            <FontSailecMedium/>
            <FontSailecBold/>
            <FontSailecLight/>
            <FontHarmonyRegular/>
            <FontHarmonyLite/>

            <Router>
                <Routes>

                    <Route exact path='/' element={
                        <div>
                            <Header selected={LABEL.init}/>
                            <BodyIndex/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/us' element={
                        <div>
                            <Header selected={LABEL.us}/>
                            <BodyUs/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/ourProposal' element={
                        <div>
                            <Header selected={LABEL.ourProposal}/>
                            <BodyOurProposal/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/contact' element={
                        <div>
                            <Header selected={LABEL.contact}/>
                            <BodyContact/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/service' element={
                        <div>
                            <Header selected={LABEL.service}/>
                            <BodyService/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/conditionRules' element={
                        <div>
                            <Header selected={LABEL.conditionRules}/>
                            <BodyConditionRules/>
                            <Footer/>
                        </div>
                    }/>

                    <Route path='/conditionsPDF' element={
                        <div>
                            <PDFViewer pdf_path={PathPDFConditions}/>
                        </div>
                    }/>

                    <Route path='/rulesPDF' element={
                        <div>
                            <PDFViewer pdf_path={PathPDFRules}/>
                        </div>
                    }/>

                </Routes>
            </Router>
        </div>
    )
}

export default App;