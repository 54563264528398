import React from 'react';
import PropTypes from 'prop-types';
import 'materialize-css';
import styled from 'styled-components';
import { Navbar, NavItem } from 'react-materialize';
import { LabelSailecMedium } from '../atoms/label.jsx';
import { COLOR, SIZE, SCREEN_MEDIA, LABEL, URL } from '../../utils/constants.js';
import { openNewTab } from '../../utils/utils.js';
import Button from '../atoms/button.jsx';
import SVG from 'react-inlinesvg';

import PathLogo from '../../icons/ic_logo.svg';
import PathBurger from '../../icons/ic_burger_3.svg';
import PathIcComputer from '../../icons/ic_computer.svg';


const Logo = styled(SVG)`
    fill: ${COLOR.secondary};
    height: 40px;
    top: 10px;
    position: relative;
`;
const Burger = styled(SVG)`
    fill: ${COLOR.secondary};
    width: 20px;
    position: relative;
`;

const logo_dom = <a href='/'><Logo src={PathLogo}></Logo></a>
const burger_dom = <Burger src={PathBurger}></Burger>;


const NavbarStyled = styled(Navbar)`
    @media only screen and ${SCREEN_MEDIA.small} {
        padding: 0 0px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        padding: 0 10px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        padding: 0 100px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        padding: 0 100px;
        background-color: ${COLOR.primary};
    }
`;

const NavItemStyled = styled(NavItem)`
    background-color: ${props => props.selected ? COLOR.primaryDark : COLOR.primary};
`;

const NavItemLabelStyled = styled(LabelSailecMedium)`
    font-size: ${SIZE.body};
    color: ${COLOR.secondary};
`;

const Header = (props) => {

    var init_selected = false;
    var us_selected = false;
    var ourProposal_selected = false;
    var contact_selected = false;
    var service_selected = false;

    switch (props.selected) {
        case LABEL.init:
            init_selected = true;
            break;
        case LABEL.us:
            us_selected = true;
            break;
        case LABEL.ourProposal:
            ourProposal_selected = true;
            break;
        case LABEL.contact:
            contact_selected= true;
            break;
        case LABEL.service:
            service_selected= true;
            break;
    }
    

    return (
        <NavbarStyled 
            alignLinks='right'
            brand={logo_dom}
            fixed={true}
            menuIcon={burger_dom}>

            <NavItemStyled
                href='/'
                selected={init_selected}>
                <NavItemLabelStyled>
                    {LABEL.init}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/service'
                selected={service_selected}>
                <NavItemLabelStyled>
                    {LABEL.service}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/us'
                selected={us_selected}>
                <NavItemLabelStyled>
                    {LABEL.us}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='ourProposal'
                selected={ourProposal_selected}>
                <NavItemLabelStyled>
                    {LABEL.ourProposal}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/contact'
                selected={contact_selected}>
                <NavItemLabelStyled>
                    {LABEL.contact}
                </NavItemLabelStyled>
            </NavItemStyled>

            <Button 
                margin='10px 10px 0 0' 
                bg_color={COLOR.secondaryClear}
                border_width='1px'
                border_color={COLOR.secondary}
                text_color={COLOR.white}
                ic_path={PathIcComputer}
                ic_size='22px'
                ic_color={ COLOR.white }
                onClick={ () => { openNewTab(URL.virtualRoom) } }>
                Aula virtual
            </Button>

        </NavbarStyled>
    );
    
}

Header.propTypes = {
    selected: PropTypes.string
}

export default Header;