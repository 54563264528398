import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import TextInput from '../atoms/textinput.jsx';
import Button from '../atoms/button.jsx';
import { LabelSailecMedium } from '../atoms/label.jsx';
import { COLOR, SIZE } from '../../utils/constants.js';

const Containter = styled.div`
    overflow: auto; // To cover height with contents
    padding: 30px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02);
`;

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_pqm421y', 'template_3v5zb1t', form.current, 'user_dPLUfbkku7BYu2jVrZZyt')
            .then((result) => {
                console.log("message sent");
                console.log(result.text);
                alert("Mensaje recibido.\nEn breve nos pondremos en contacto contigo");
            }, (error) => {
                console.log("message error");
                console.log(error.text);
            });
        e.target.reset();
    }

    return(
        <Containter>
            <LabelSailecMedium 
                margin='10px 0 0 0' 
                text_size={SIZE.title}>
                REGÍSTRATE
            </LabelSailecMedium>

            <form ref={form} onSubmit={sendEmail}>
            <TextInput 
                id='from_name'
                name='from_name'
                type='text'
                placeholder='Nombre del apoderado'
                margin='40px 0 20px 0'
                onChange={ () => {} }/>
            <TextInput 
                id='celular'
                name='celular'
                type='tel' 
                placeholder='Celular'
                margin='20px 0'
                onChange={ () => {} }/>
            <TextInput 
                id='email'
                name='email'
                type='email' 
                placeholder='Correo'
                margin='20px 0'
                onChange={ () => {} }/>
            <TextInput
                id='course'
                name='course'
                type='text'
                placeholder='Grado de interés'
                margin='20px 0'
                onChange={ () => {} }/>
            <Button
                type='submit'
                bg_color={COLOR.primary}
                margin='30px 0 0 0'
                float='right'
                onChange={ () => {} }>
                Enviar
            </Button>
            </form>
            
        </Containter>
    );
}

export default Contact;