import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR } from '../../utils/constants.js';

const Img = styled.img`
    src: ${props => props.src ? props.src : 'none'};
    border-color: ${props => props.stroke_color ? props.stroke_color : COLOR.gold};
    border-width: ${props => props.stroke_width ? props.stroke_width : '0px'};
    border-style: solid;
    border-radius: ${props => props.bg_corner_radius ? props.bg_corner_radius : '0px'};
    padding: ${props => props.padding ? props.padding : '0px'};
    width: ${props => props.width ? props.width : 'none'};
    height: ${props => props.height ? props.height : 'none'};
`;

const Image = (props) => {
    return (
    <Img
        className={props.className}
        src={props.src}
        stroke_color={props.stroke_color}
        stroke_width={props.stroke_width}
        bg_corner_radius={props.bg_corner_radius}
        padding={props.padding}
        width={props.width}
        height={props.height}/>
    )
}

Image.propTypes = {
    className: PropTypes.string, //Must have className to apply styled component css properties.
    src: PropTypes.string,
    stroke_color: PropTypes.string,
    stroke_width: PropTypes.string,
    bg_corner_radius: PropTypes.string,
    padding: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

export default Image;