import React from 'react';
import { Container, Col } from 'react-materialize';
import { SIZE, LABEL, URL } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Banner from '../organism/banner.jsx';
import TextButton from '../atoms/textbutton.jsx';


const BodyConditionRules = () => {
    return(
    <div>
        <Banner title={LABEL.conditionRules}/>

        <Container>
            <Row margin='0 0'>
                <Path 
                    endpoint={LABEL.conditionRules}
                    margin='20px 0' />
            </Row>
            <Row>
                <Col s={12} m={12} l={12} xl={12}>
                    <TextButton 
                        text_size={SIZE.headline}
                        href={URL.urlDriveConditions}
                        target='_blank'>
                        {LABEL.conditions}
                    </TextButton>
                </Col>
                <Col s={12} m={12} l={12} xl={12}>
                    <TextButton 
                        text_size={SIZE.headline}
                        href={URL.urlDriveRules}
                        target='_blank'
                        margin='30px 0 0 0'>
                        {LABEL.rules}
                    </TextButton>
                </Col>
            </Row>
        </Container>
    </div>
    )
}

export default BodyConditionRules;