import React from 'react';
import styled from 'styled-components';
import { Container, Col, Parallax } from 'react-materialize';
import { COLOR, SIZE, SCREEN_MEDIA, LABEL, URL } from '../../utils/constants.js';
import Slider from '../organism/slider.jsx';
import Row from '../organism/row.jsx';
import RowInfo from '../organism/rowinfo.jsx';
import Divider from '../atoms/divider.jsx';
import Image from '../atoms/image.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import FixedButtons from '../molecules/fixedbuttons.jsx';

import PathIcHeart from '../../icons/ic_heart.svg';
import PathIcAtom from '../../icons/ic_atom.svg';
import PathIcInitial from '../../icons/ic_edu_initial.svg';
import PathIcNotebook from '../../icons/ic_edu_notebook.svg';
import PathIcGraduation from '../../icons/ic_edu_graduation.svg';

import PathWapp from '../../icons/ic_wapp.svg';
import PathFb from '../../icons/ic_fb.svg';
import PathInsta from '../../icons/ic_insta.svg';

import PathImg02 from '../../images/img_10.jpg';
import PathImg06 from '../../images/img_6.jpg';
import PathImg12 from '../../images/img_12.jpg';
import PathImg13 from '../../images/img_13.jpg';


const ParallaxStyled = styled(Parallax)`
    margin: 60px 0;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: -1;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: ${COLOR.secondary};
        opacity: 0.6;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 900px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 600px;
    }
`;

const ParallaxBody = styled(Container)`
    position: relative;
    z-index: 1;
`;

const BodyIndex = () => {
    return (
        <div>
            <FixedButtons
                items={[
                    { href: URL.whatsapp, ic_path: PathWapp, ic_size: '50px' },
                    { href: URL.facebook, ic_path: PathFb, ic_size: '50px' },
                    { href: URL.instagram, ic_path: PathInsta, ic_size: '50px' }
                    ]}
                aligment='right'/>
            <Slider 
                showBubble={true}
                />

            <Container>
                <Row>
                    <Col s={12} m={12} l={12} xl={12}>
                        <a href='/service'>
                            <Image 
                                className='responsive-img' 
                                src={PathImg02}
                                bg_corner_radius='10px'/>
                        </a>
                    </Col>
                </Row>

                <Divider/>

                <Row>
                    <Col s={12} m={12} l={12} xl={12}>
                        <a href={URL.tutorialVirtualRoom} target='_blank'>
                            <Image 
                                className='responsive-img' 
                                src={PathImg06}
                                bg_corner_radius='10px'/>
                        </a>
                    </Col>
                </Row>

                <Divider/>

                <RowInfo
                    margin='80px 0'
                    img_path={PathImg12}
                    img_right={false}
                    title='Nosotros'
                    descs={['El colegio Real Pacífico, con alto nivel de enseñanza y altas expectativas de rendimiento académico. Nuestros alumnos egresan preparados para aprobar el examen e ingresar a la universidad.']}
                    />
            </Container>

            <ParallaxStyled
                image={<img alt="" src={PathImg13}/>}
                options={{responsiveThreshold: 0}}
                children={
                    <ParallaxBody>
                        <Row>
                            <TitleSect color={COLOR.white}>Nuestra Propuesta</TitleSect>
                        </Row>
                        <Row>
                            <Col s={12} m={6} l={3} xl={3}>
                                <CardContent 
                                    ic_path={PathIcInitial} 
                                    ic_height='35px'
                                    ic_color={COLOR.white}
                                    title={LABEL.gradeInitial} 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white} 
                                    alignment='center'
                                    margin='30px 0px'/>
                            </Col>
                            <Col s={12} m={6} l={3} xl={3}>
                                <CardContent 
                                    ic_path={PathIcNotebook} 
                                    ic_height='35px'
                                    ic_color={COLOR.white}
                                    title={LABEL.gradePrimary} 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    alignment='center'
                                    margin='30px 0px'/>
                            </Col>
                            <Col s={12} m={6} l={3} xl={3}>
                                <CardContent 
                                    ic_path={PathIcAtom} 
                                    ic_height='35px'
                                    ic_color={COLOR.white}
                                    title={LABEL.gradeSecondary} 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    alignment='center'
                                    margin='30px 0px'/>
                            </Col>
                            <Col s={12} m={6} l={3} xl={3}>
                                <CardContent 
                                    ic_path={PathIcGraduation} 
                                    ic_height='35px'
                                    ic_color={COLOR.white}
                                    title={LABEL.gradePreUni} 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    alignment='center'
                                    margin='30px 0px'/>
                            </Col>
                        </Row>
                    </ParallaxBody>
                }
            />

            <Container>
                <Row>
                    <Col s={12} m={12} l={6} xl={6}>
                        <CardContent 
                            ic_path={PathIcAtom} 
                            ic_height='60px'
                            ic_color={COLOR.primaryDark}
                            title='20'
                            title_suffix='+'
                            descs={['Años de Experiencia']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>

                    <Col s={12} m={12} l={6} xl={6}>
                        <CardContent 
                            ic_path={PathIcHeart} 
                            ic_height='60px'
                            ic_color={COLOR.primaryDark}
                            title='1000'
                            title_suffix='+'
                            descs={['Ingresantes a la universidad']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>
                </Row>
            </Container>
            
        </div>
    );
}

export default BodyIndex;