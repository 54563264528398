
const COLOR = {
    primary: '#FFCA18',
    primaryDark: '#F0BC11',
    primaryClear: '#FFEEB5',
    gold: '#C99B02',
    secondary: '#101439',
    secondaryClear: '#7278A8',
    background: '#FFFFF6',
    white: '#FFFFFF',
    black: '#333333',
    gray: '#999999',
    grayDark: '#777777',
    grayClear: '#EDEDED',
    red: '#FF6961'
}

const SIZE = {
    display: '36px',
    headline: '28px',
    title: '22px',
    subtitle: '18px',
    body: '16px',
    caption: '13px',
    small: '11px',
    row_margin: '80px 0',
    inp_radious: '3px' // Input border radious
}

const SCREEN_MEDIA = {
    small: `(min-width: 0px) and (max-width: 600px)`,
    medium : `(min-width: 600px) and (max-width: 992px)`,
    long: `(min-width: 992px) and (max-width: 1200px)`,
    xlong: `(min-width: 1200px)`
}

const LABEL = {
    init: 'Inicio',
    us: 'Nosotros',
    ourProposal: 'Nuestra Propuesta',
    contact: 'Contacto',
    service: 'Matrícula 2024',
    conditionRules: 'Condiciones y Reglamento',
    conditions: 'Condiciones',
    rules: 'Reglamento',
    gradeInitial: 'Inicial',
    gradePrimary: 'Primaria',
    gradeSecondary: 'Secundaria',
    gradePreUni: 'Preuniversidad',
    contactEmail: 'vicantropolis@hotmail.com',
    contactCellPhone: '947670628',
    contactLandLine: ''
}

const URL = {
    virtualRoom: 'https://classroom.google.com/u/0/h',
    tutorialVirtualRoom: 'https://www.youtube.com/watch?v=X3lhDkDg9sA',
    urlDriveConditions: 'https://drive.google.com/file/d/1zc4aHQ6g8JZ0EFebk8BDWq_5Bq8Ukusk/view?fbclid=IwAR0tdJsBYrekcdzw07qu2jDSWqvPMWAeClgTaFucoRLFT1-KUfmMZU0Skgs',
    urlDriveRules: 'https://drive.google.com/file/d/1zAUGWOt-2kyu4x5EqTbmkxijzeC8AZzU/view?fbclid=IwAR1oog05mELlCg9aK7Eh5mI5rMzJMabIJyRPu2G_5YWj64a2Inr2-cxarIs',
    whatsapp: "https://api.whatsapp.com/send/?phone=51901617680&text=Deseo+obtener+información&app_absent=0",
    facebook: "https://www.facebook.com/pg/colegiorealpacifico/",
    instagram: "https://www.instagram.com/colegiorealpacifico/"
}

export {
    COLOR,
    SIZE,
    SCREEN_MEDIA,
    LABEL,
    URL
}