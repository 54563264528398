import React from 'react';
import styled from 'styled-components';
import Banner from '../organism/banner.jsx';
import { Container, Col } from 'react-materialize';
import Row from '../organism/row.jsx';
import { COLOR, LABEL, SCREEN_MEDIA } from '../../utils/constants.js';
import Path from '../molecules/path.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import RowInfo from '../organism/rowinfo.jsx';
import Card from '../molecules/card.jsx';
import Divider from '../atoms/divider.jsx';

import PathImg15 from '../../images/img_15.jpg';

import PathIcAtom from '../../icons/ic_atom.svg';
import PathIcHeart from '../../icons/ic_heart.svg';
import PathIcUsCommunication from '../../icons/ic_us_communication.png';
import PathIcUsCompromiso from '../../icons/ic_us_compromiso.png';
import PathIcUsInnovation from '../../icons/ic_us_innovation.png';
import PathIcUsLoveLearn from '../../icons/ic_us_love_learn.png';
import PathIcUsRespect from '../../icons/ic_us_respect.png';


const CardStyled = styled(Card)`
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 340px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 520px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 520px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 430px;
    }
`;

const BodyUs = () => {
    return (
        <div>
            <Banner title={LABEL.us}/>

            <Container>
                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.us}
                        margin='20px 0' />
                </Row>

                <RowInfo
                    margin='40px 0'
                    img_path={PathImg15}
                    img_right={false}
                    title='Historia'
                    descs={['Un estudiante egresa de la secundaria, postula a la universidad y no ingresa. Lo logra después de un año de preparación, como mínimo, en una academia preuniversitaria.', 'Fue necesaria la creación de un gran colegio. El colegio Real Pacífico, con alto nivel de enseñanza y altas expectativas de rendimiento académico. Los alumnos egresan preparados para aprobar el examen e ingresar a la universidad.']}
                    subtitle1='Misión'
                    desc1='Ser los responsables de dar una formación integral a cada uno de nuestros alumnos en las distintas etapas de su desarrollo.'
                    subtitle2='Visión'
                    desc2='Formar personas con alta calidad humana, con sólidos conocimientos académicos, con habilidades sociales y capaces de adaptarse a los cambios.'
                    />

                <Divider/>

                <Row margin='80px 0 30px 0'>
                    <TitleSect>Valores</TitleSect>
                </Row>

                <Row margin='30px 0 0 0'>
                    <Col s={12} m={6} l={4} xl={4}>
                        <CardStyled
                            ic_path={PathIcUsLoveLearn}
                            ic_height='45px'
                            title='Amor al aprendizaje'
                            title_separation='20px'
                            desc_separation='15px'
                            margin='15px 0 0 0'
                            descs={['Aprender a aprender. El aprendizaje es "acción", si no hay acción, es sólo información.']}/>
                    </Col>
                    <Col s={12} m={6} l={4} xl={4}>
                        <CardStyled
                            ic_path={PathIcUsRespect}
                            ic_height='45px'
                            title='Respeto'
                            title_separation='20px'
                            desc_separation='15px'
                            margin='15px 0 0 0'
                            descs={['Aprenden a valorarse, reconocer y aceptar que somos diferentes, auténticos y legítimos.']}/>
                    </Col>
                    <Col s={12} m={6} l={4} xl={4}>
                        <CardStyled
                            ic_path={PathIcUsInnovation}
                            ic_height='45px'
                            title='Innovación constante'
                            title_separation='20px'
                            desc_separation='15px'
                            margin='15px 0 0 0'
                            descs={['Entender que el cambio es constante y que siempre debemos estar acorde a las circunstancias que se den en la sociedad y en el conocimiento. La innovación también involucra a nuestra persona.']}/>
                    </Col>
                    <Col s={12} m={6} l={4} xl={4}>
                        <CardStyled
                            ic_path={PathIcUsCompromiso}
                            ic_height='45px'
                            title='Compromiso'
                            title_separation='20px'
                            desc_separation='15px'
                            margin='15px 0 0 0'
                            descs={['Que todo lo que haces, honre lo que dices. El compromiso de nuestros maestros se nota y son incentivo primordial para los estudiantes, haciéndoles personas responsables. A mayor compromiso, mayor rendimiento.']}/>
                    </Col>
                    <Col s={12} m={6} l={4} xl={4}>
                        <CardStyled
                            ic_path={PathIcUsCommunication}
                            ic_height='45px'
                            title='Comunicación auténtica'
                            title_separation='20px'
                            desc_separation='15px'
                            margin='15px 0 0 0'
                            descs={['Practicamos en que, si uno habla de un tema, el otro debe hablar de lo mismo. Podrá haber una buena comunicación si ambos se enfocan en lo mismo. Aprender a escuchar.']}/>
                    </Col>
                </Row>

                <Divider/>

                <Row>
                    <Col s={12} m={6} l={6} xl={6}>
                        <CardContent
                            ic_path={PathIcAtom}
                            ic_height='60px'
                            ic_color={COLOR.primaryDark}
                            title='20'
                            title_suffix='+'
                            subtitle='Años de experiencia'
                            alignment='center'/>
                    </Col>
                    <Col s={12} m={6} l={6} xl={6}>
                        <CardContent
                            ic_path={PathIcHeart}
                            ic_height='60px'
                            ic_color={COLOR.primaryDark}
                            title='1000'
                            title_suffix='+'
                            subtitle='Ingresantes a la universidad'
                            alignment='center'/>
                    </Col>
                </Row>

            </Container>

        </div>
    );
}

export default BodyUs;