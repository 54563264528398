import React, { useRef, useEffect } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gsap from 'gsap';
import { COLOR, SCREEN_MEDIA } from '../../utils/constants.js';
import Slide from './slide.jsx';

import PathSlide01 from '../../images/img_01.jpg';
import PathSlide02 from '../../images/img_02.jpg';
import PathSlide03 from '../../images/img_03.jpg';


const SliderContent = styled.div`
    height: 600px;
    width: 100%;
	position: relative;
	overflow: hidden;
`;

const BubbleContent = styled.div`
    height: 50px;
    width: 120px;
    margin: 0 auto;
    position: relative;
    top: 550px;
    bottom: 0;
    z-index: 40;
    > div {
        display: block;
        height: 20px;
        float: left;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        width: 120px;
        > div {
            width: 40px;
        }
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        width: 120px;
        > div {
            width: 40px;
        }
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        width: 150px;
        > div {
            width: 50px;
        }
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        width: 180px;
        > div {
            width: 60px;
        }
    }
`;

const Bubble = styled.span`
    position: absolute;
	display: block;
	border-radius: 50%;
	float: left;
	margin: 0px 14px;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 20px;
	    width: 20px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 20px;
	    width: 20px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        height: 25px;
	    width: 25px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        height: 30px;
	    width: 30px;
    }
`;

const BubbleUnder = styled(Bubble)`
    border: 1px solid ${COLOR.primaryClear};
    &:hover {
        cursor: pointer;
        background: ${COLOR.primaryClear};
    }
`;

const BubbleOver = styled(Bubble)`
    background: ${COLOR.primary};
	opacity: 0;
    visibility: hidden;
`;

const Slider = (props) => {
    var bubbles_visible = props.showBubble ? 'visible' : 'hidden';

    // store a reference to the slide title
    const slide01Ref = useRef();
    const slide02Ref = useRef();
    const slide03Ref = useRef();
    const slideRefs = [slide01Ref, slide02Ref, slide03Ref];

    const bubbleOver01Ref = useRef();
    const bubbleOver02Ref = useRef();
    const bubbleOver03Ref = useRef();
    const bubbleOverRefs = [bubbleOver01Ref, bubbleOver02Ref, bubbleOver03Ref];

    // wait until DOM has been rendered
    useEffect(() => {
        const duration_slide = 8; // duration in secs
        const duration_trans = 0.5; // duration in secs

        const slides = $('#slider_content #slide');
        const bubblesUnder = $('#slider_content #bubble_under');

        const timeLine1 = gsap.timeline({repeat: -1, delay: 0});
        const timeLine2 = gsap.timeline({repeat: -1, delay: duration_slide});
        const timeLine3 = gsap.timeline({repeat: -1, delay: duration_slide * 2});
        const timeLines = [timeLine1, timeLine2, timeLine3];

        /************** SLIDER AUTOMÁTICO **************/
        for (var i=0; i < slideRefs.length; i++) {

            var slideRef = slideRefs[i];
            var slide = slides[i];
            var slideTitle = $(slide).find('p').eq(0);
            var slideDesc = $(slide).find('p').eq(1);

            timeLines[i].to(slideRef.current, {autoAlpha: 1, visibility: 'visible', duration: duration_trans}, 'begin');
            timeLines[i].to(slideTitle, {x: '18%', duration: duration_slide}, 'begin');
            timeLines[i].to(slideDesc, {x: '-10%', duration: duration_slide}, 'begin');
            timeLines[i].to(bubbleOverRefs[i].current, {autoAlpha:1, visibility:"visible", duration: duration_trans}, 'begin');

            timeLines[i].to(slideRef.current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans}, `begin+=${duration_slide}`);
            timeLines[i].to(slideRef.current, {autoAlpha: 0, visibility: 'hidden', duration: (duration_slide * 2 ) - duration_trans}, `begin+=${duration_slide + duration_trans}`); // keeps the slide invisible up to its turn.
            timeLines[i].to(bubbleOverRefs[i].current, {autoAlpha:0, visibility:"hidden", duration: duration_trans}, `begin+=${duration_slide}`);
        }

        bubblesUnder.on("click", function() {
            var index = $('#slider_content #bubble_under').index( this ); // return the clicked index
            for (var j = 0; j< slideRefs.length; j++) {
                if (j === index) {
                    var timeLine = timeLines[j];
                    timeLine.restart();
                    setTimeout(function(){
                        timeLine.kill();
                    }, duration_slide * 1000);
                }
                else {
                    timeLines[j].kill();
                    gsap.to(slideRefs[j].current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans});
                    gsap.to(bubbleOverRefs[j].current, {autoAlpha: 0, visibility: 'hidden', duration: duration_trans});
                }
            }
        });
    });

    return (
        <SliderContent id='slider_content'>

            <Slide
                ref={slide01Ref}
                bg_path={PathSlide01}
                title='Excelente nivel académico'
                description='Potenciamos el desarrollo intelectual y personal de nuestros estudiantes a través de un eficiente sistema educativo en el campo de las Matemáticas, Ciencias, Letras y Desarrollo Personal.'/>

            <Slide 
                ref={slide02Ref}
                bg_path={PathSlide02}
                title='La mejor propuesta educativa'
                description='"La exigencia saca lo mejor de cada estudiante"'/>
            
            <Slide 
                ref={slide03Ref}
                bg_path={PathSlide03}
                title='La mejor plana docente'
                description='Los mejores docentes, con la más alta experiencia académica. Siempre actualizados.'/>

            <BubbleContent style={{visibility: bubbles_visible}}>
                <div>
                    <BubbleUnder id='bubble_under'/>
                    <BubbleOver id='bubble_over' ref={bubbleOver01Ref}/>
                </div>
                <div>
                    <BubbleUnder id='bubble_under'/>
                    <BubbleOver id='bubble_over' ref={bubbleOver02Ref}/>
                </div>
                <div>
                    <BubbleUnder id='bubble_under'/>
                    <BubbleOver id='bubble_over' ref={bubbleOver03Ref}/>
                </div>
            </BubbleContent>
        </SliderContent>
    );
}

Slider.propTypes = {
    showBubble: PropTypes.bool.isRequired
}


export default Slider;