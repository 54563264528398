import React from 'react';
import styled from 'styled-components';
import { Container, Col } from 'react-materialize';
import { LABEL, SCREEN_MEDIA } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Divider from '../atoms/divider.jsx'
import Card from '../molecules/card.jsx';
import Banner from '../organism/banner.jsx';
import Path from '../molecules/path.jsx';

import PathIcPhone from '../../icons/ic_phone.svg';
import PathIcGps from '../../icons/ic_gps.svg';
import PathIcMail from '../../icons/ic_mail.svg';

const MapContent = styled.div`
    position: relative;
    width: 100%;
    height: 470px;
    > iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
    margin: 0px 0;
    @media only screen and ${SCREEN_MEDIA.small} {
        margin: 50px 0 0 0;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        margin: 50px 0 0 0;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        margin: 50px 0 0 0;
    }
`;

const CardStyled = styled(Card)`
    height: 230px;
    margin: 20px 0 0 0;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 200px;
    }
`;

const BodyContact = () => {
    return (
        <div>
            <Banner title={LABEL.contact}/>

            <Container>
                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.contact}
                        margin='20px 0' />
                </Row>
                <Row className='valign-wrapper'>
                    <Col s={12} m={12} l={12} xl={12}>
                        <MapContent>
                            <iframe src="https://maps.google.com/maps?q=Avenida%20pac%C3%ADfico%20578%2C%20Nuevo%20Chimbote%2C%20Per%C3%BA&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                allowFullScreen/>
                        </MapContent>
                    </Col>
                </Row>

                <Divider/>

                <Row>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcPhone}
                            title='Teléfonos'
                            title_separation='15px'
                            descs={[LABEL.contactCellPhone]}
                            alignment='center'
                            />
                    </Col>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcMail}
                            title='Correos'
                            title_separation='15px'
                            descs={[LABEL.contactEmail]}
                            alignment='center'
                            />
                    </Col>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcGps}
                            title='Dirección'
                            title_separation='15px'
                            descs={['Av. Pacífico 578, Nuevo Chimbote (02711) - Perú']}
                            alignment='center'
                            />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BodyContact;