import React from 'react';
import styled from 'styled-components';
import { Container, Col } from 'react-materialize';
import { LABEL, SCREEN_MEDIA } from '../../utils/constants.js';
import TitleSect from '../atoms/titlesect.jsx';
import Path from '../molecules/path.jsx';
import Row from '../organism/row.jsx';
import Card from '../molecules/card.jsx';
import Banner from '../organism/banner.jsx';
import Contact from '../organism/contact.jsx';

import PathIcPhone from '../../icons/ic_phone.svg';
import PathIcGps from '../../icons/ic_gps.svg';
import PathIcMail from '../../icons/ic_mail.svg';


const CardStyled = styled(Card)`
    height: 230px;
    margin: 20px 0 0 0;
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 200px;
    }
`;

const BodyService = () => {
    return (
        <div>
            <Banner title={LABEL.service}/>

            <Container>
                <Row margin='0 0'>
                    <Path 
                        endpoint={LABEL.service}
                        margin='20px 0' />
                </Row>

                <Row margin='60px 0 0 0'>
                    <TitleSect>Matrícula</TitleSect>
                </Row>

                <Row margin='50px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={8} offset='xl2'>
                        <Contact/>
                    </Col>
                </Row>

                <Row margin='80px 0 0 0'>
                    <TitleSect>Contáctanos</TitleSect>
                </Row>

                <Row margin='60px 0 0 0'>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcPhone}
                            title='Teléfonos'
                            title_separation='15px'
                            descs={[LABEL.contactCellPhone]}
                            alignment='center'
                            />
                    </Col>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcMail}
                            title='Correos'
                            title_separation='15px'
                            descs={[LABEL.contactEmail]}
                            alignment='center'
                            />
                    </Col>
                    <Col s={12} m={8} l={4} xl={4} offset='m2'>
                        <CardStyled
                            ic_path={PathIcGps}
                            title='Dirección'
                            title_separation='15px'
                            descs={['Av. Pacífico 578, Nuevo Chimbote (02711) - Perú']}
                            alignment='center'
                            />
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default BodyService;