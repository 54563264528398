import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-materialize';
import { COLOR, SIZE } from '../../utils/constants.js';
import { LabelSailecBold, LabelSailecRegular } from '../atoms/label.jsx';
import Row from './row.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import Bullets from '../molecules/bullets.jsx';


const Content = styled.div`
    margin: 0 0 0 25px;
`;

const Subtitle = styled(LabelSailecBold)`
    font-size: ${SIZE.title};
    color: ${COLOR.black};
    margin: 30px 0 0 0;
`;

const Description = styled(LabelSailecRegular)`
    margin: ${props => props.margin ? props.margin : '0 0'};
    color: ${COLOR.black};
    font-size: ${SIZE.body};
`;

const DescsContent = styled.div`
    margin: 35px 0 0 0;
`;

const RowInfo = (props) => {

    const listDesc = props.descs?.map((desc, index) =>
        <Description
            key={index}
            text_color={COLOR.black}
            text_size={SIZE.body}
            margin='25px 0 0 0'>
            {desc}
        </Description>
    );
    const descs = <DescsContent>{listDesc}</DescsContent>

    if(props.img_right === true) {
        return (
            <Row className='valign-wrapper' margin={props.margin} padding={props.padding}>
                <Col s={12} m={12} l={6} xl={6}>
                    <Content>
                        <TitleSect>{props.title}</TitleSect>
                        {descs}
                        <Bullets items={props.bullet_items}/>

                        <Subtitle>
                            {props.subtitle1}
                        </Subtitle>
                        <Description margin='10px 0px'>
                            {props.desc1}
                        </Description>

                        <Subtitle>
                            {props.subtitle2}
                        </Subtitle>
                        <Description margin='10px 0px'>
                            {props.desc2}
                        </Description>
                    </Content>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <img className='responsive-img' src={props.img_path}/>
                </Col>
            </Row>
        );
    } else {
        return (
            <Row className='valign-wrapper' margin={props.margin} padding={props.padding}>
                <Col s={12} m={12} l={6} xl={6}>
                    <img className='responsive-img' src={props.img_path}/>
                </Col>
                <Col s={12} m={12} l={6} xl={6}>
                    <Content>
                        <TitleSect>{props.title}</TitleSect>
                        {descs}
                        <Bullets items={props.bullet_items}/>

                        <Subtitle>
                            {props.subtitle1}
                        </Subtitle>
                        <Description margin='10px 0px'>
                            {props.desc1}
                        </Description>

                        <Subtitle>
                            {props.subtitle2}
                        </Subtitle>
                        <Description margin='10px 0px'>
                            {props.desc2}
                        </Description>
                    </Content>
                </Col>
            </Row>
        );
    }
    
}

RowInfo.propTypes = {
    img_path: PropTypes.string.isRequired,
    img_right: PropTypes.bool,
    title: PropTypes.string,
    descs: PropTypes.arrayOf(string),
    bullet_items: PropTypes.arrayOf(object),
    subtitle1: PropTypes.string,
    desc1: PropTypes.string,
    subtitle2: PropTypes.string,
    desc2: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string
}

export default RowInfo;