import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


const PDFContent = styled.iframe`
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display:block; 
    overflow:hidden;
    overflow-x:hidden;
    overflow-y:hidden;
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px
`;


const PDFViewer = (props) => {
    return(
        <div>
            <PDFContent src={props.pdf_path} frameBorder="0" />
        </div>
    )
}

PDFViewer.propTypes = {
    pdf_path: PropTypes.string
}

export default PDFViewer;